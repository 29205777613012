<template>
  <div class="lastBox" style="background-color: #f8fcff;">
    <div class="banner">
      <div class="banner-title"><span>已成功估价车辆数量</span></div>
      <div class="estimate">
        <div class="numBox">
          <span>1</span>
          <span>2</span>
          <span>3</span>
        </div>
        <img src="../../../public/img/point.png" />
        <div class="numBox">
          <span>1</span>
          <span>2</span>
          <span>3</span>
        </div>
      </div>
    </div>
    <el-card :body-style="{ padding: '0 0 50px 0' }"
      style="width:62.5%;margin: 20px auto;text-align: center;margin-top: 70px;" class="box-card">
      <div>
        <p>残值领取查询</p>
        <div class="list">
          <span>姓名</span>
          <span>{{ data.carOwner }}</span>
        </div>
        <div class="list">
          <span>车牌号</span>
          <span>{{ data.plateNumber }}</span>
        </div>
        <div class="list">
          <span>办理时间</span>
          <span>{{ data.creationTime }}</span>
        </div>
        <div class="list" v-if="data.realWeight !== null">
          <span>车重</span>
          <span>{{ data.realWeight }}t</span>
        </div>
        <div class="list" v-if="data.realWeight == null">
          <span>车重</span>
          <span>未称重</span>
        </div>
        <div class="list" v-if="data.subsidyMoney !== null">
          <span>残值价额</span>
          <span style="font-weight: bold;color: #CA1C26;">{{ data.subsidyMoney }}元</span>
        </div>
        <div class="list" v-if="data.subsidyMoney == null">
          <span>残值价额</span>
          <span>等待审核完成</span>
        </div>
        <div class="list" v-if="data.weightTime !== null">
          <span>已经受理</span>
          <span>{{ data.weightTime }}</span>
        </div>
        <div class="list" v-if="data.examineTime !== null">
          <span>正在审核</span>
          <span>{{ data.examineTime }}</span>
        </div>
        <div class="list" v-if="data.completeTime !== null">
          <span>已完成</span>
          <span>{{ data.completeTime }}</span>
        </div>
        <div class="list" v-if="data.weightTime == null">
          <span>未受理</span>
          <span style="font-weight: 600;">审核没通过 请查看原因 重新完成提交报废汽车申请</span>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "adressMaintain",
  computed: {
    ...mapState({
      get_ResidualSearch: (state) => {
        return state.home.get_ResidualSearch;
      },
    }),
  },
  data() {
    return {
      data: [],
    };
  },
  created() {},
  mounted() {
    this.getNews();
  },
  methods: {
    getNews(index) {
      this.$store.dispatch("home/ResidualSearch", {}).then(() => {
        this.data = this.get_ResidualSearch;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  height: 440px;
  width: 100%;
  background-size: 100%;
  background-image: url(/img/homeIcon/dalan.jpg);
  background-repeat: no-repeat;
  position: relative;
  padding-top: 60px;
  .banner-title {
    text-align: center;
    line-height: 140px;
    color: #ffffff;
    font-weight: 500;
    font-size: 58px;
    span {
      transform: skew(-12deg);
      display: inline-block;
    }
  }
  .estimate {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .numBox {
    span {
      display: inline-block;
      width: 100px;
      height: 160px;
      background: #ffffff;
      opacity: 0.8;
      border-radius: 8px;
      margin-right: 10px;
      font-size: 120px;
      font-family: PingFang SC;
      font-weight: bold;
      font-style: italic;
      color: #2197a2;
    }
  }
  img {
    width: 16px;
    height: 19px;
    margin-right: 10px;
  }
}
.box-card {
  position: relative;
  top: -140px;
  p {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    margin: 49px 0 29px 0;
  }
  .list {
    width: 590px;
    height: 48px;
    margin: 0 auto;
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    line-height: 48px;
    padding: 0 20px 0 20px;
    margin-bottom: 20px;
    span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }
  }
}
.steps {
  height: 200px;
  width: 630px;
  margin: 0 auto;
}
/deep/.el-step__title.is-wait {
  color: #333333;
}
/deep/.el-step__description.is-wait {
  color: #333333;
}
</style>
